export const UpsellingHeadersDGG = [
    {
        id: 1,
        title: 'Firma'
    },
    {
        id: 2,
        title: 'Closer'
    },
    {
        id: 3,
        title: 'bAV'
    },
    {
        id: 4,
        title: 'bKV'
    },
    {
        id: 5,
        title: 'GehaltsExtras'
    },
    {
        id: 6,
        title: 'CLS'
    },
    {
        id: 7,
        title: 'HRMT'
    },
    {
        id: 8,
        title: 'NBG'
    },
    {
        id: 9,
        title: 'bBU',
    },
]