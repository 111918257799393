export const UserManagementHeaders=[
    {
        id:1,
        title:'Name'
    },
    {
        id:2,
        title:'E-Mail'
    },
    {
        id:3,
        title:'VP-Nummer'
    },
    {
        id:4,
        title:'Aktiv'
    },
    {
        id:5,
        title:'Datum erstellt'
    },
    {
        id:6,
        title:'letzte Anmeldung'
    },
]