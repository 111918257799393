import React, {useEffect, useState} from "react";
import {useStateValue} from "../../states/StateProvider";
import Api from "../../Api/api";
import {useParams} from "react-router";
import {toast} from "react-toastify";
import {AES, enc} from "crypto-js";
import {BeatLoader, ClipLoader} from "react-spinners";

const ReportingUpselling = () => {
    const [{
        secretKey,
    }] = useStateValue();
    const [stepsLoading, setStepsLoading] = useState(false)
    const [info, setInfo] = useState([])
    const [data, setData] = useState([])
    const [options, setOptions] = useState([])
    const [infoLoading, setInfoLoading] = useState(false)
    const param = useParams()
    const decryptedBytes = localStorage.getItem('user') ? AES.decrypt(localStorage.getItem('user'), secretKey) : false;
    const user = JSON.parse(decryptedBytes.toString(enc.Utf8))

    useEffect(() => {
        setInfoLoading(true)
        Api().get(`/sp_getUpsellingReports/dgg`).then(res => {
            setInfo(res.data)
            setInfoLoading(false)
        }).catch(e => {
            toast.error('Firmendetails konnten nicht geladen werden!')
            setInfoLoading(false)
        })

        Api().get(`sp_getUpsellingOptions/${'dgg'}`)
            .then(res => {
                setOptions(res.data)
            })

    }, []);


    function optionSelected(e) {
        if (e.target.value === 'Wähle eine Option') {
            toast.warning('Wählen Sie zunächst eine Option')
        } else {
            setStepsLoading(true)
            Api().get(`/sp_getSingleUSReport/${e.target.value}`).then(res => {
                res.data.map(d => {
                    options.map(o => {
                        if (d.usID === o.ID) {
                            d.usID = o.title
                        }
                    })
                })
                setData(res.data)
                setStepsLoading(false)
            }).catch(e => {
                toast.error('Firmendetails konnten nicht geladen werden!')
                setStepsLoading(false)
            })
        }

    }


    return (
        <div className='dashboardContainer'>
                 <h2 className={`text-2xl lg:text-left float-left ${!infoLoading ? '':'opacity-0'}`}> Upselling-Berichtstabelle</h2>

            {
                infoLoading &&
                <tr className='centerItemsAbsolute'>
                    <td><BeatLoader size={50} color={'#b4b4b4'}/></td>
                </tr>
            }
            {
                !infoLoading &&
                <section>
                    <span>Wählen Sie eine Tabelle aus</span>
                    <select onChange={optionSelected}
                            className='bg-white border ml-4 my-2 px-5 py-2 border-offWhite rounded-lg'>
                        <option value={null}>Wähle eine Option
                        </option>
                        :
                        {
                            info.map(i => (
                                <option key={i.ID} value={i.ID}>{i.Auswertung}</option>
                            ))
                        }
                    </select>
                </section>

            }
            {
                stepsLoading &&
                <tr className='centerItemsAbsolute'>
                    <td><ClipLoader size={50} color={'#b4b4b4'}/></td>
                </tr>
            }
            {
                !stepsLoading && data.length > 0 &&
                <div className='my-3 rounded-lg sm:block w-12/12 bg-white'>
                    <table className='text-left table bg-white w-full'>
                        <thead>
                        <tr>
                            <th scope="col" className="text-grey pl-1.5">
                                Upselling
                            </th>
                            <th scope="col" className="text-grey pl-1.5">
                                bAV
                            </th>
                            <th scope="col" className=" text-grey pl-1.5">
                                bKV
                            </th>
                            <th scope="col" className=" text-grey pl-1.5">
                                GE
                            </th>
                            <th scope="col" className=" text-grey pl-1.5">
                                CLS
                            </th>
                            <th scope="col" className="text-grey pl-1.5">
                                HRMT
                            </th>
                            <th scope="col" className="text-grey pl-1.5">
                                NBG
                            </th>
                            <th scope="col" className=" text-grey pl-1.5">
                                bBU
                            </th>
                        </tr>
                        </thead>
                        <tbody className='gap-4'>
                        {
                            data.map((d) => (
                                <tr key={d.usID} className='border-y border-silver border-x-0 text-sm'>
                                    <td>{d.usID}</td>
                                    <td>{d.bAV}</td>
                                    <td>{d.bKV}</td>
                                    <td>{d.GE}</td>
                                    <td>{d.CLS}</td>
                                    <td>{d.HRMT}</td>
                                    <td>{d.NBG}</td>
                                    <td>{d.bBU}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default ReportingUpselling