export const StorfalleHeadersDGG = [
    {
        id: 1,
        title: 'Firma Kurz'
    },
    {
        id: 2,
        title: 'Firma'
    },
    {
        id: 3,
        title: 'MA'
    },
    {
        id: 4,
        title: 'Zust. Berater'
    },
    {
        id: 5,
        title: 'DGAPI KAM'
    },
    {
        id: 7,
        title: 'P-Status'
    },
    {
        id: 8,
        title: 'P-Datum'
    },
    {
        id: 9,
        title: 'StörfallDatum'
    },
    {
        id: 10,
        title: 'Bemerkung',
    },
]